<template>
  <div class="_bg-default _80w _100vh pb-3 px-3">
    <v-row class="_100top">
      <v-col cols="12" md="6">
        <v-card class="radius-card pa-2">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Judul Kegiatan"
                    v-model="title"
                    required
                    dense
                    solo
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    label="Description"
                    solo
                    hide-details
                    v-model="description"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Lokasi"
                    required
                    v-model="location"
                    solo
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="calendar1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="start_evt"
                        label="Waktu Mulai"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="start_evt"
                      @input="calendar1 = false"
                      :min="$date().format('YYYY-MM-DD')"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="calendar2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="start_evt ? false : true"
                        v-model="end_evt"
                        label="Waktu Berakhir"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="end_evt"
                      :min="$date(start_evt).format('YYYY-MM-DD')"
                      @input="calendar2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    :items="listType"
                    dense
                    solo
                    v-model="typeEvent"
                    hide-details="auto"
                    label="Tipe Event"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="typeEvent == 'berbayar'">
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Harga"
                    required
                    v-model="price"
                    solo
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Nomor Rekening"
                    required
                    v-model="no_rek"
                    solo
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Atas nama"
                    required
                    v-model="nama_rek"
                    solo
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Nama Bank"
                    required
                    v-model="nama_bank"
                    solo
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Fasilitas"
                    required
                    v-model="amenities"
                    solo
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Pemateri"
                    required
                    v-model="speaker"
                    solo
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Kuota"
                    type="number"
                    required
                    v-model="kuota"
                    solo
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    :items="listVisible"
                    dense
                    item-text="state"
                    item-value="abbr"
                    solo
                    v-model="visible"
                    hide-details="auto"
                    label="Privasi"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <!-- UPLOAD COVER -->
        <v-card class="radius-card mt-3 __relative" height="300">
          <div class="__template__cover">
            <img :src="dp" class="cover__" alt="img" v-if="dp" />
          </div>
          <div class="d-flex justify-center mt-4">
            <v-btn rounded outlined color="green">
              <div class="upload-btn-wrapper add-width">
                <div class="pa-1">
                  <v-icon>mdi-upload</v-icon> Pilih Poster
                  <input type="file" name="myfile" @change="getPic($event)" />
                </div>
              </div>
            </v-btn>
          </div>
        </v-card>

        <!-- SELECT CATEGORY -->
        <h3 class="mt-3">Categories:</h3>
        <v-card class="radius-card mt-3 pa-3" :color="bgColor" height="auto">
          <v-radio-group v-model="category">
            <v-radio
              label="Psikologi Klinis"
              value="Psikologi Klinis"
              @change="bgColor = 'a__'"
            ></v-radio>
            <v-radio
              label="Psikologi Pendidikan"
              value="Psikologi Pendidikan"
              @change="bgColor = 'b__'"
            ></v-radio>
            <v-radio
              label="PIO"
              value="Psikologi Industri & Organisasi"
              @change="bgColor = 'c__'"
            ></v-radio>
          </v-radio-group>
        </v-card>
      </v-col>

      <!-- FORM CREATE AGENDA -->
      <v-col cols="12" md="6">
        <!-- CALENDAR -->
        <v-card class="radius-card __overflow__hidden">
          <v-sheet height="auto">
            <v-toolbar color="grey lighten-5" flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
                small
              >
                Today
              </v-btn>
              <v-btn fab text x-small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text x-small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <small v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </small>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="auto">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
            ></v-calendar>
          </v-sheet>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <footer class="d-flex justify-end mt-5">
          <v-btn v-if="!loading" dark color="green" @click="createAgenda"
            >SAVE</v-btn
          >
          <v-btn dark color="green" v-if="loading">
            <v-progress-circular
              size="20"
              class="mr-2"
              indeterminate
            ></v-progress-circular>
            loading...
          </v-btn>
        </footer>
      </v-col>
    </v-row>
    <div class="pa-3" v-if="dataAgenda">
      <ViewAgenda
        v-bind:data="dataAgenda"
        v-bind:dialog="dialogAgenda"
        @close="closeDialog"
        @fetch="fetchItem"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import ViewAgenda from "../../components/Admin/Modal/viewAgenda.vue";
export default {
  name: "shadowsaman",
  components: {
    ViewAgenda,
  },
  data() {
    return {
      //  V-MODEL CREATE EVT
      title: "",
      location: "",
      price: "",
      amenities: "",
      speaker: "",
      description: "",
      start_evt: null,
      end_evt: null,
      calendar1: false,
      calendar2: false,
      category: "",
      cover: null,
      dp: null,
      bgColor: "",
      // CALENDAR CONFIG
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      kuota: null,
      no_rek: null,
      nama_rek: '',
      nama_bank: '',
      listType: ["berbayar", "gratis"],
      listVisible: [
        {
          state: "Hanya Anggota",
          abbr: "private",
        },
        {
          state: "Publik",
          abbr: "public",
        },
      ],
      visible: "",
      typeEvent: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dialogAgenda: false,
      dataAgenda: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      agenda: (state) => state.agenda.agenda,
    }),
    events() {
      let evt = [];
      if (this.agenda) {
        let agenda = this.agenda.data;
        for (let i = 0; i < agenda.length; i++) {
          evt.push({
            id: agenda[i].id,
            name: agenda[i].judul,
            start: agenda[i].start_date,
            end: agenda[i].end_date,
            timed: null,
            color:
              agenda[i].nama_kategori == "Psikologi Klinis"
                ? "a__"
                : agenda[i].nama_kategori == "Psikologi Pendidikan"
                ? "b__"
                : agenda[i].nama_kategori == "Psikologi Industri & Organisasi"
                ? "b__"
                : "red",
          });
        }
      }
      return evt;
    },
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      let data = {
        search: "",
      };
      this.$store.dispatch("agenda/listAgenda", data);
    },
    closeDialog() {
      this.dataAgenda = null;
      this.dialogAgenda = false;
    },
    createAgenda() {
      this.loading = true;
      let data = new FormData();
      data.append("judul", this.title);
      data.append("tipe_agenda", this.typeEvent);
      data.append("biaya", this.price);
      data.append("visible", this.visible);
      data.append("kuota", this.kuota);
      data.append("no_rek", this.no_rek);
      data.append("nama_rek", this.nama_rek);
      data.append("nama_bank", this.nama_bank);
      data.append("nama_kategori", this.category);
      data.append("deskripsi", this.description);
      data.append("lokasi", this.location);
      data.append("start_date", this.start_evt);
      data.append("end_date", this.end_evt);
      data.append("fasilitas", this.amenities);
      data.append("pemateri", this.speaker);
      data.append("objPoster", this.cover);
      this.$store
        .dispatch("agenda/createAgenda", data)
        .then((data) => {
          console.log(data);
          this.loading = false;
          if (data.data.status == "success") {
            this.title = "";
            this.category = "";
            this.description = "";
            this.location = "";
            this.amenities = "";
            this.speaker = "";
            this.typeEvent = "";
            this.cover = null;
            this.dp = null;
            this.start_evt = null;
            this.end_evt = null;
            this.price = null;
            this.visible = null;
            this.kuota = null;
            this.no_rek = null;
            this.fetchItem();
            Swal.fire({
              title: data.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true,
            });
          } else {
            Swal.fire({
              title: data.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getPic(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = (f) => {
        this.dp = f.target.result;
      };
      fr.readAsDataURL(file);
      this.cover = file;
    },

    // set up caelndar
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.focus = "";
    },

    viewAgenda(data) {
      this.dialogAgenda = true;
      this.dataAgenda = data;
      console.log("agus cabul");
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedElement = nativeEvent.target;
        this.idTask = parseInt(event.id);
        this.viewAgenda(event);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style scoped>
.__overflow__hidden {
  overflow: hidden !important;
  box-sizing: border-box;
}
.__template__cover {
  width: 100%;
  height: 230px;
  background: #f3e5f5;
}
.__relative {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.cover__ {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
